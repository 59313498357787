import React from "react"
import { Box } from "grommet"

import SEO from "../components/seo"
import Graphic404 from "../assets/Graphic404.svg"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Box
      background="white"
      width="full"
      style={{ height: "100vh" }}
      justify="center"
      align="center"
    >
      <Graphic404 style={{ width: "40%" }} />
    </Box>
  </>
)

export default NotFoundPage
